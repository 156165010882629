var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "text-sm-center",
              attrs: { xs10: "", md8: "", lg6: "", xl6: "" }
            },
            [
              _c("div", { staticClass: "text-sm-center" }, [
                _vm.isAcceptance
                  ? _c("img", {
                      staticClass: "logo",
                      attrs: {
                        src: require("@/assets/images/logos/AcceptatieOmgeving-Mijnbouwschade.png")
                      }
                    })
                  : _c("img", {
                      staticClass: "logo",
                      attrs: {
                        src: require("@/assets/images/logos/logo-img.png")
                      }
                    })
              ]),
              _c(
                "div",
                { staticClass: "elementPanel elementPanel--shadowed mt-3" },
                [
                  [
                    _c(
                      "v-layout",
                      [
                        _c("v-flex", { attrs: { "text-xs-center": "" } }, [
                          _c("h2", [
                            _vm._v(
                              "De applicatie is tijdelijk niet beschikbaar."
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }