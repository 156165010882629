import { Component, Vue } from 'vue-property-decorator';

@Component<Maintenance>({})
export default class Maintenance extends Vue {
  public $pageTitle = 'Maintenance';

  public mounted() {
    //
  }

  protected get isAcceptance() {
    return this.$store.state.isAcceptance;
  }
}
